import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Image, message, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import { AddBTN } from "../../../components/button";
import Table2 from "../../../components/tables/table2";
import {
  DefaultAdminPage,
  DefaultUserPage,
  primaryColor,
  Types,
} from "../../../config";
import { fakeImage } from "../../../config/fakeData";
import UserModal from "./user-config";
import { useTranslation } from "react-i18next";
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
 

const findRoutes = {
  admin: DefaultAdminPage,
  user: DefaultUserPage,
};

export default function UserList(props) {
  const { setData, userData } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const getUserList = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `user?page=${data?.page || page || 1}&pageSize=${
        data?.pageSize || pageSize
      }`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setList([]);
    }
  };
  const DeleteUser = async (id) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `user/${id}`,
      "delete",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setLoading(true);
      getUserList();
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getUserList();

    return () => {};
  }, []);

  const SwitchAccount = async (id) => {
    const response = await MakeApiCall(
      `user/switch-user/${id}`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status === true) {
      const type = Types?.[response?.data?.user_data?.user_type];
      const obj = {
        admin: userData,
        user: response?.data,
        loginType: type,
        token: response?.data?.auth_token,
      };

      setData(obj);
      navigate(findRoutes?.[type]);
    } else {
      message.warning(response?.message);
    }
  };

  const [visible, setVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const handleCancel = () => {
    setVisible(false);
    setEditingUser(null);
  };

  const CreateUser = async (data) => {
    delete data.confirm_password;
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `user`,
      "post",
      {
        ...data,
      },
      true,
      {},
      userData?.token
    );

    if (response?.status === true) {
      handleCancel();
      setLoading(true);
      getUserList();
      message.destroy();
      message.success(response?.message || "Successfully created");
    } else {
      message.destroy();
      message.warning(response?.message || "Failed to create");
    }
  };
  const UpdateUser = async (data) => {
    if (!editingUser?.id) return;
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `user/${editingUser?.id}`,
      "put",
      data,
      true,
      {},
      userData?.token
    );

    if (response?.status === true) {
      handleCancel();
      setLoading(true);
      getUserList();
      message.destroy();
      message.success(response?.message || "Successfully updated");
    } else {
      message.destroy();
      message.warning(response?.message || "Failed to update");
    }
  };

  const handleSubmit = (values) => {
    if (editingUser) {
      UpdateUser(values);
    } else {
      CreateUser(values);
    }
  };

  const handleEdit = (user) => {
    setEditingUser(user);
    setVisible(true);
  };

  // Columns configuration
  const columns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: t("Name"),
      dataIndex: "pic",
      key: "pic",
      sorter: (a, b) => a.name.localeCompare(b.name),
      
    sortIcon: (direction) => direction?.sortOrder === "ascend" ? <CaretUpOutlined /> : <CaretDownOutlined />,
      render: (pic, record) => (
        <div>
          <Image
            style={{ borderRadius: "100%" }}
            width={50}
            src={pic || fakeImage}
          />
          <b className="ms-3">{record.name}</b>
        </div>
      ),
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t("Seller Name"),
      dataIndex: "seller_name",
      key: "seller_name",
      sorter: (a, b) => a.seller_name.localeCompare(b.seller_name),
    },
    {
      title: t("Mobile"),
      dataIndex: "contact_no",
      key: "contact_no",
      sorter: (a, b) => a.contact_no.localeCompare(b.contact_no),
      render: (e) => {
        return <>{e || "-"}</>;
      },
    },
    {
      title: t("Switch User"),
      key: "switchUser",
      align: "center",

      render: (_, row) => (
        <Icon
          onClick={() => SwitchAccount(row?.id)}
          icon="clarity:switch-line"
          width={30}
          style={{ color: primaryColor, cursor: "pointer" }}
        />
      ),
    },
    {
      title: t("Action"),
      key: "action",
      align: "center",
      render: (_, row) => (
        <div>
          <Button
            onClick={() => {
              handleEdit(row);
            }}
            type="link"
          >
            <Icon icon="basil:edit-outline" width={24} />
          </Button>
          <Popconfirm
            placement="left"
            title={t("Are you sure you want to delete?")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              DeleteUser(row?.id);
            }}
          >
            <Button type="link">
              <Icon
                icon="fluent:delete-20-filled"
                style={{ color: "#ff5454" }}
                width={24}
              />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="card  pulse">
      <div className="card-header px-3">
        <div className="card-title"></div>
        <div className="card-toolbar">
          <AddBTN onClick={() => setVisible(true)} type="primary">
            {t("+ Add User")}
          </AddBTN>
        </div>
      </div>
      <div className="card-body px-3">
        <Table2
          scroll={{ x: "max-content" }}
          columns={columns}
          loading={loading}
          dataSource={list}
          pagination={false}
        />
      </div>
      <UserModal
        visible={visible}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        initialValues={editingUser}
      />
    </div>
  );
}
