import { Icon } from "@iconify/react/dist/iconify.js";
import {
  
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { MakeApiCall } from "../../../apis";
import { AddCommas, ConvertParams } from "../../../config";
import Table1 from "../../../components/tables/table1";
import { useNavigate } from "react-router-dom";
import Subscription from "./lib/subscription";

const { Option } = Select;

export default function SuccessfullPaymentList(props) {
  const { userData } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [isAlreadySubscribed, setIsAlreadySubscribed] = useState(null);

  const getSubscriptionList = async (data) => {
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `subscription${ConvertParams(data)}`,
        "get",
        null,
        true,
        {},
        userData?.token
      );

      if (response?.status) {
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setList(response?.data?.records || []);
        setIsAlreadySubscribed(response?.data?.records?.length > 0);
        // setIsAlreadySubscribed(false);
      } else {
        message.warning(response?.message);
        setList([]);
        setIsAlreadySubscribed(false);
      }
    } catch (error) {
      message.error(t("Something went wrong"));
      setIsAlreadySubscribed(false);
      setList([]);
    } finally {
      setLoading(false);
    }
  };
  console.log("isAlreadySubscribed",isAlreadySubscribed)

  useEffect(() => {
    getSubscriptionList({
      page: page,
      "per-page": pageSize,
    });
  }, [page, pageSize]);

  const columns =  [
    {
      title: t("Product Name"),
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: t("Subscription ID"),
      dataIndex: "subscription_id",
      key: "subscription_id",
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (value) => `$${value.toFixed(2)}`,
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("Ends At"),
      dataIndex: "ends_at",
      key: "ends_at",
      render: (value) => dayjs(value).format("MMM DD, YYYY hh:mm A"),
    },
    {
      title: t("Created At"),
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => dayjs(value).format("MMM DD, YYYY hh:mm A"),
    },
  ]


  if (isAlreadySubscribed === null || loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  return (
    <>
      {isAlreadySubscribed ? (
          <>
        <div className="card fadeInUp">
          <div className="card-header mt-2">
            <div className="card-title d-flex align-items-center justify-content-center">
              {t("Payments")}
            </div>
          </div>
          <div className="card-body p-5">
            <Table1
              scroll={{ x: "max-content" }}
              dataSource={list}
              loading={loading}
              columns={columns}
              rowKey="id"
              pagination={{
                current: page,
                pageSize: pageSize,
                total: totalPage,
                onChange: (newPage, newPageSize) => {
                  setPage(newPage);
                  setPageSize(newPageSize);
                },
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total, range) => `${AddCommas(range[0] || 0)}-${AddCommas(range[1] || 0)} of ${AddCommas(total || 0)} ${t("items")}`,
              }}
            />
          </div>
        </div>
        </>
      ) : (
          <Subscription userData={userData} setIsAlreadySubscribed={setIsAlreadySubscribed} isAlreadySubscribed={isAlreadySubscribed}/>
        )}
        </>
  );
}