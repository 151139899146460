import Sider from "antd/es/layout/Sider";
import styled from "styled-components";
import { primaryColor, secondaryColor, thirdColor } from "../../../config";

export const Wrapper = styled(Sider)`
  margin-right: 0px;
  margin: 0px 0px;
  border-radius: 0px 0px 0px 0px;

  background: #f8f9fa !important;
  border-right: 1px dashed #311365;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .sidebar-expand {
    border: 1px solid ${primaryColor} !important;
    svg {
      color: ${primaryColor} !important;
    }

    width: 30px !important;
    border-radius: 10px !important;

    height: 52px !important;
    margin: 20px 10px 20px 0px !important;
    cursor: pointer !important;
  }
  .sidebar-collapsed {
    .sidebar-expand {
      display: none !important;
    }
    &:hover {
      .demo-logo-vertical {
        display: none !important;
      }
      .sidebar-expand {
        height: 52px !important;
        margin: 20px 16px !important;
        background: rgb(83 76 171 / 19%) !important;
        border-radius: 10px !important;
        width: -webkit-fill-available !important;
        display: flex !important;
        align-items: center !important;
        svg {
          color: ${thirdColor} !important;
        }
      }
    }
  }
  .ant-menu-light {
    background: transparent !important;
  }

  .demo-logo-vertical {
    height: 52px;
    margin: 20px 16px;
    // background: rgb(83 76 171 / 19%);
    border-radius: 10px;
    width: -webkit-fill-available;
  }
  .ant-menu-inline {
    border-inline-end: none !important;
  }
  .sidebar-view {
    overflow: auto;
    min-height: 300px;
    height: calc(100vh - 160px);
    padding: 0px 15px;
  }
  .ant-menu-item {
    .ant-menu-title-content {
      a {
        color: ${thirdColor} !important;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
  .ant-menu-inline {
    background: transparent !important;
  }
  .ant-menu-item {
    width: calc(100% - 0px) !important;
    margin-inline: 0px !important;
    margin: 10px 0px;
  }
  .ant-menu-title-content {
    padding-left: 10px;
    border-radius: 11px;
  }
  .ant-menu-item-selected {
    padding-left: 24px;

    box-shadow: rgb(83 76 171 / 30%) 0px 10px 20px 0px;
    background-color: ${thirdColor} !important;
    border-radius: 7px;

    .ant-menu-title-content {
      a {
        color: #fff !important;
      }

      padding-left: 10px;
      border-radius: 11px;
      font-weight: 600;
    }
    .ant-menu-item-icon {
      svg {
        color: #fff !important;
      }
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-item-selected {
      a {
        color: ${secondaryColor} !important;
      }
    }
    .ant-menu-submenu-title {
      .ant-menu-item-icon {
        svg {
          color: ${secondaryColor} !important;
        }
      }
      .ant-menu-title-content {
        font-size: 16px !important;
      }
    }
  }
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      .ant-menu-title-content {
        // font-size: 14px !important;
      }
    }
  }
  .ant-menu-sub {
    .ant-menu-title-content {
      padding-left: 60px;
      border-radius: 11px;
    }
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    .ant-menu-title-content {
      margin-inline-start: 0px !important;
    }
  }
  .ant-menu-sub {
    .ant-menu-title-content {
      padding-left: 15px !important;
      margin-left: 35px !important;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    &:hover {
      padding-left: 24px;

      border-radius: 7px;
      background: ${secondaryColor} !important;

      .ant-menu-title-content {
        a {
          color: #fff !important;
        }
      }
      .ant-menu-item-icon {
        svg {
          color: #fff !important;
        }
      }
    }
  }
  .ant-menu-submenu-title {
    margin-inline: 0px !important;
  }
  .ant-menu-sub {
    padding-left: 20px !important;
  }

  .ant-menu-inline-collapsed {
    border-inline-end: none !important;
    .ant-menu-item-icon {
      padding: 0 !important;
    }
    .ant-menu-submenu-title,
    .ant-menu-item-selected {
      padding-left: 28px !important;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: 10px !important;
  }
  .ant-menu-title-content {
    font-weight: 700 !important;
  }
  .ant-menu-sub {
    .ant-menu-title-content {
      font-weight: 500 !important;
    }
  }
  .ant-menu-inline-collapsed {
    .ant-menu-item {
      padding-left: 30px !important;
    }
    .ant-menu-submenu-vertical {
      .ant-menu-submenu-title {
        // padding-left: 30px !important;
      }
    }
  }
  .ant-menu-item::after {
    background: #ff9874;
    width: 4px;
    margin: 5px 0px;
    border-radius: 10px 0px 0px 10px;
  }
  .ant-menu-inline-collapsed {
    .ant-menu-item {
      padding-left: 17px !important;
      padding-top: 4px !important;
    }
  }

  .ant-menu-submenu-title {
    .ant-menu-title-content {
      font-weight: 500 !important;
      color: ${thirdColor} !important;
      font-size: 16px !important;
      font-weight: 300 !important;
    }
  }
  .ant-menu-submenu-title {
    &:hover {
      color: #fff !important;
      .ant-menu-title-content {
        color: #fff !important;
      }
    }
  }
  .ant-menu-sub {
    .ant-menu-item-selected {
      a {
        color: #fff !important;
      }
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      background: #534caa;
      color: #fff;
      .ant-menu-item-icon > svg {
        color: #fff !important;
      }
      .ant-menu-title-content {
        font-weight: 500 !important;
        color: #fff !important;
        font-size: 16px !important;
        font-weight: 300 !important;
      }
    }
  }
  .ant-menu-inline-collapsed {
    .ant-menu-submenu-title {
      padding-left: 11px !important;
    }
    .ant-menu-item {
      width: calc(100% - 5px) !important;
      padding-left: 11px !important;
    }
  }
`;
