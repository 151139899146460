import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Table,
  Tag,
  Checkbox,
  Tooltip,
  Input,
  Image,
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import Table1 from "../../../components/tables/table1";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { DymmyImage } from "../../../config/fakeData";

import { MakeApiCall } from "../../../apis";
import { AddCommas, ConvertParams, PaginationConfig } from "../../../config";
import { useTranslation } from "react-i18next";
const { Option } = Select;

export default function Product(props) {
  const { setData, userData } = props;
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null); // State to hold the clicked tag

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [searchASIN, setSearchASIN] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);

  const getProductList = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `products${ConvertParams(data)}`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setList([]);
    }
  };

  const updateProductList = async (data) => {
    setUpdateLoading(true);
    const response = await MakeApiCall(
      `product/${data?.id}`,
      "put",
      data,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setUpdateLoading(false);
      setLoading(true);
      setSelectedTag({});
      setIsModalVisible(false);
      getProductList({
        asin1: searchASIN,
        page: page,
        pageSize: pageSize,
      });

      setList((prev) => {
        return prev?.map((d) => {
          if (d?.id === data?.id) {
            return {
              ...d,
              cogs_loading: false,
              cogs_edit: false,
            };
          }
          return d;
        });
      });
    } else {
      setUpdateLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(false);
    setList([]);
    getProductList({
      asin1: searchASIN,
      page: page,
      "per-page": pageSize,
    });
    return () => {};
  }, []);

  const showModal = (product_type) => {
    setSelectedTag(product_type); // Set the clicked tag data
    setIsModalVisible(true); // Open modal
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Close modal
  };

  const columns = [
    {
      title: t("Product"),
      dataIndex: "product",
      key: "product",
      width: 400,
      render: (text, row) => (
        <div
          className="d-flex align-items-center"
          style={{
            background: "#f0f5ff",
            padding: "10px",
            borderRadius: "12px",
          }}
        >
          <Image
            style={{ borderRadius: "18px" }}
            src={row?.image_url || DymmyImage}
            width={75}
            height={75}
            onError={(e) => {
              e.target.src = DymmyImage;
            }}
          />
          <div className="ms-3">
            <div className="d-flex">
              <Tag color="#AF69ED" bordered={false}>
                {row?.asin1 || "-"}
              </Tag>
              <Tag color="#C8A2C9" bordered={false}>
                {row?.seller_sku || "-"}
              </Tag>
            </div>
            <div
              className="my-2"
              style={{
                width: "350px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
              }}
            >
              {row?.item_name || "-"}
            </div>
            <div>
              <Tag color="#957A81" bordered={false}>
                ${AddCommas(row?.price)}
              </Tag>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: t("FBA/FBM"),
      dataIndex: "product_type",
      key: "product_type",
      render: (product_type, row) => (
        <>
          <Tag
            color="#601A3E"
            onClick={() => showModal(row)}
            style={{ cursor: "pointer" }}
          >
            {product_type || "Add"}
          </Tag>
        </>
      ),
    },
    {
      title: (
        <span>
          {t("COGS")}{" "}
          <Tooltip title="Cost of Goods Sold">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "cogs",
      key: "cogs",
      align: "center",
      width: 150,
      render: (e, row) => {
        return (
          <div>
            <Input
              value={row?.cogs_edit ? row?.cogs_value : e}
              className="w-150px"
              onChange={(e) => {
                setList((prev) => {
                  return prev?.map((d) => {
                    if (d?.id === row?.id) {
                      return {
                        ...d,
                        cogs_value: e.target.value,
                      };
                    }
                    return d;
                  });
                });
              }}
              variant={row?.cogs_edit ? "outlined" : "borderless"}
              suffix={
                <div className="d-flex align-items-center">
                  {row?.cogs_edit ? (
                    <>
                      {row?.cogs_loading ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />
                      ) : (
                        <Icon
                          icon="lets-icons:check-fill"
                          color="green"
                          width={20}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateProductList({
                              id: row?.id,
                              cogs: row?.cogs_value,
                            });

                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.id === row?.id) {
                                  return {
                                    ...d,
                                    cogs_loading: true,
                                  };
                                }
                                return d;
                              });
                            });
                          }}
                        />
                      )}
                      <Icon
                        className="ms-1"
                        icon="gridicons:cross-circle"
                        color="red"
                        width={20}
                        onClick={(e) => {
                          e.stopPropagation();
                          setList((prev) => {
                            return prev?.map((d) => {
                              if (d?.id === row?.id) {
                                delete d?.cogs_value;
                                return {
                                  ...d,
                                  cogs_edit: false,
                                  cogs_loading: false,
                                  cogs_value: row?.cogs,
                                };
                              }
                              return d;
                            });
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Icon
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setList((prev) => {
                          return prev?.map((d) => {
                            if (d?.id === row?.id) {
                              return {
                                ...d,
                                cogs_edit: true,
                                cogs_loading: false,
                                cogs_value: row?.cogs || 0,
                              };
                            }
                            return d;
                          });
                        });
                      }}
                      icon="iconamoon:edit-duotone"
                      width={20}
                    />
                  )}
                </div>
              }
            />
          </div>
        );
      },
    },
    {
      title: (
        <span>
          {t("FBM Shipping Cost")}{" "}
          <Tooltip title="Fulfillment by Merchant">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      align: "center",
      dataIndex: "fbm_shipping_cost",
      key: "fbm_shipping_cost",
      render: (e, row) => {
        if (row?.product_type === "FBA") {
          return e || 0;
        }
        return (
          <div>
            <Input
              value={
                row?.fbm_shipping_cost_edit ? row?.fbm_shipping_cost_value : e
              }
              className="w-150px"
              onChange={(e) => {
                setList((prev) => {
                  return prev?.map((d) => {
                    if (d?.id === row?.id) {
                      return {
                        ...d,
                        fbm_shipping_cost_value: e.target.value,
                      };
                    }
                    return d;
                  });
                });
              }}
              variant={row?.fbm_shipping_cost_edit ? "outlined" : "borderless"}
              suffix={
                <div className="d-flex align-items-center">
                  {row?.fbm_shipping_cost_edit ? (
                    <>
                      {row?.fbm_shipping_cost_loading ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />
                      ) : (
                        <Icon
                          icon="lets-icons:check-fill"
                          color="green"
                          width={20}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateProductList({
                              id: row?.id,
                              fbm_shipping_cost: row?.fbm_shipping_cost_value,
                            });

                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.id === row?.id) {
                                  return {
                                    ...d,
                                    fbm_shipping_cost_loading: true,
                                  };
                                }
                                return d;
                              });
                            });
                          }}
                        />
                      )}
                      <Icon
                        className="ms-1"
                        icon="gridicons:cross-circle"
                        color="red"
                        width={20}
                        onClick={(e) => {
                          e.stopPropagation();
                          setList((prev) => {
                            return prev?.map((d) => {
                              if (d?.id === row?.id) {
                                delete d?.fbm_shipping_cost_value;
                                return {
                                  ...d,
                                  fbm_shipping_cost_edit: false,
                                  fbm_shipping_cost_loading: false,
                                  fbm_shipping_cost_value:
                                    row?.fbm_shipping_cost,
                                };
                              }
                              return d;
                            });
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Icon
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setList((prev) => {
                          return prev?.map((d) => {
                            if (d?.id === row?.id) {
                              return {
                                ...d,
                                fbm_shipping_cost_edit: true,
                                fbm_shipping_cost_loading: false,
                                fbm_shipping_cost_value:
                                  row?.fbm_shipping_cost || 0,
                              };
                            }
                            return d;
                          });
                        });
                      }}
                      icon="iconamoon:edit-duotone"
                      width={20}
                    />
                  )}
                </div>
              }
            />
          </div>
        );
      },
    },
    {
      title: t("VAT rate"),
      dataIndex: "vat_rate",
      key: "vat_rate",
      align: "center",
      width: 150,
      render: (e, row) => {
        return (
          <div>
            <Input
              value={row?.vat_edit ? row?.vat_value : e}
              className="w-150px"
              onChange={(e) => {
                setList((prev) => {
                  return prev?.map((d) => {
                    if (d?.id === row?.id) {
                      return {
                        ...d,
                        vat_value: e.target.value,
                      };
                    }
                    return d;
                  });
                });
              }}
              variant={row?.vat_edit ? "outlined" : "borderless"}
              suffix={
                <div className="d-flex align-items-center">
                  {row?.vat_edit ? (
                    <>
                      {row?.vat_loading ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />
                      ) : (
                        <Icon
                          icon="lets-icons:check-fill"
                          color="green"
                          width={20}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateProductList({
                              id: row?.id,
                              vat_rate: row?.vat_value,
                            });

                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.id === row?.id) {
                                  return {
                                    ...d,
                                    vat_loading: true,
                                  };
                                }
                                return d;
                              });
                            });
                          }}
                        />
                      )}
                      <Icon
                        className="ms-1"
                        icon="gridicons:cross-circle"
                        color="red"
                        width={20}
                        onClick={(e) => {
                          e.stopPropagation();
                          setList((prev) => {
                            return prev?.map((d) => {
                              if (d?.id === row?.id) {
                                delete d?.vat_value;
                                return {
                                  ...d,
                                  vat_edit: false,
                                  vat_loading: false,
                                  vat_value: row?.vat_rate,
                                };
                              }
                              return d;
                            });
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Icon
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setList((prev) => {
                          return prev?.map((d) => {
                            if (d?.id === row?.id) {
                              return {
                                ...d,
                                vat_edit: true,
                                vat_loading: false,
                                vat_value: row?.vat_rate || 0,
                              };
                            }
                            return d;
                          });
                        });
                      }}
                      icon="iconamoon:edit-duotone"
                      width={20}
                    />
                  )}
                </div>
              }
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="card fadeInUp">
        <div className="card-header mt-2">
          <div className="card-title d-flex align-items-center justify-content-center">
            {t("Products")}
          </div>
          <div className="card-toolbar">
            <Input
              prefix={<Icon width={20} icon={"iconamoon:search-duotone"} />}
              placeholder={t("Search By ASIN")}
              className="w-200px"
              onChange={(e) => setSearchASIN(e.target.value)}
              value={searchASIN}
              onPressEnter={() => {
                setPage(1);

                setLoading(true);
                setList([]);
                getProductList({
                  asin1: searchASIN,
                  page: 1,
                  "per-page": pageSize,
                });
              }}
            />
          </div>
        </div>
        <div className="card-body p-5">
          <Table1
            scroll={{ x: "max-content" }}
            dataSource={list}
            loading={loading}
            columns={columns}
            rowKey="id"
            pagination={PaginationConfig({
              pageSize,
              page,
              totalPage,
              onChange: (e, f) => {
                setPageSize(f);
                setPage(e);
                getProductList({
                  page: e,
                  "per-page": f,
                  asin1: searchASIN,
                });
              },
            })}
          />
        </div>
      </div>

      <Modal
        title="FBA/FBM"
        open={isModalVisible}
        onOk={() => {
          updateProductList({
            id: selectedTag?.id,
            product_type: selectedTag?.product_type,
          });
          setUpdateLoading(true);
        }}
        okButtonProps={{ loading: updateLoading }}
        onCancel={handleCancel}
      >
        <div style={{ marginTop: "20px", minHeight: "90px" }}>
          <label
            style={{ fontSize: "13px", fontWeight: 500, color: "#777b81" }}
          >
            {t("Select Option")}
          </label>
          <Select
            value={selectedTag?.product_type}
            onChange={(e) => {
              setSelectedTag((prev) => {
                return {
                  ...prev,
                  product_type: e,
                };
              });
            }}
            style={{ width: "100%", marginTop: "5px" }}
          >
            <Option value="FBA">{t("FBA")}</Option>
            <Option value="FBM">{t("FBM")}</Option>
          </Select>
        </div>
      </Modal>
    </>
  );
}
