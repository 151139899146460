import {
  Button,
  Card,
  Flex,
  Spin,
  Table,
  Tabs,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import dayjs from "dayjs";

import { MakeApiCall } from "../../../apis";
import { urlDecode } from "../../../config";
import AdsAmazon from "./lib/ads-amazon";
import AdsProfiles from "./lib/ads-profiles";
import SpModal from "./lib/sp-modal";
import { useTranslation } from "react-i18next";

const MarketplaceView = (props) => {
  const {
    APPID,
    SPCallBack,
    generateRefreshToken,
    list,
    AdsCallBack,
    ClientID,
    AdsRefreshToken,
    saveProfile,
    returnURL,
    userData,
  } = props;
  const { t } = useTranslation();
  const [spList, setSpList] = useState([]);
  const [spLoading, setSpLoading] = useState(true);
  const [selectedType, setSelectedType] = useState("1");
  const [spModal, setSpModal] = useState(false);
  const location = useLocation();

  const [adsModalOpen, setAdsModalOpen] = useState(false);

  const [adList, setAdList] = useState([]);
  const [adListModal, setAdListModal] = useState(false);

  const getSPList = async () => {
    setSpLoading(true);
    const response = await MakeApiCall(
      list,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setSpLoading(false);
      setSpList(response?.data || []);
    } else {
      message.destroy();
      message.warning(response?.message);
      setSpLoading(false);
      setSpList([]);
    }
  };
  const GenerateRefreshToken = async (data) => {
    setSpLoading(true);
    const response = await MakeApiCall(
      generateRefreshToken,
      "post",
      data,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      message.success(response?.message);
      message.destroy();
      setTimeout(() => {
        window.location.assign(SPCallBack);
      }, 500);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const AdsGenerateRefreshToken = async (data) => {
    const response = await MakeApiCall(
      AdsRefreshToken,
      "post",
      data,
      true,
      {},
      userData?.token
    );
    try {
      if (response?.status) {
        message.success(response?.message);
        message.destroy();
        setAdList(JSON.parse(response?.data));
        setAdListModal(true);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.warning("Something went wrong");
    }
  };

  useEffect(() => {
    if (location.pathname.includes(SPCallBack)) {
      setSelectedType("1");
      if (location.search) {
        const { spapi_oauth_code, selling_partner_id, state } =
          urlDecode(location);
        const obj = {
          spapi_oauth_code: spapi_oauth_code,
          selling_partner_id: selling_partner_id,
          seller_name: decodeURIComponent(state?.split("!!")?.[1] || ""),
          marketplace_id: state?.split("!!")?.[3] || "",
          other_marketplace_ids: localStorage.getItem("other_marketplace")
            ? JSON.parse(localStorage.getItem("other_marketplace"))?.join(",")
            : "",
        };
        message.destroy();
        message.loading("Loading...", 0);
        GenerateRefreshToken(obj);
      }
    } else if (location.pathname.includes(AdsCallBack)) {
      setSelectedType("2");
      if (location.search) {
        const { code, marketplace_id } = urlDecode(location);

        const obj = {
          ad_code: code,
          marketplace_id: marketplace_id,
        };

        message.destroy();
        message.loading("Loading...", 0);
        AdsGenerateRefreshToken(obj);
      }
    }
  }, [location]);

  useEffect(() => {
    getSPList();
    return () => {};
  }, []);

  const dataSP = [
    {
      key: "1",
      sellerAccountName: "Seller A",
      arn: "ARN123",
      region: "US",
      marketplace: "Amazon",
      createdAt: "2024-10-10",
      updatedAt: "2024-10-11",
    },
    {
      key: "2",
      sellerAccountName: "Seller B",
      arn: "ARN456",
      region: "EU",
      marketplace: "eBay",
      createdAt: "2024-10-09",
      updatedAt: "2024-10-10",
    },
    // Add more rows as needed
  ];

  // Define table columns
  const columnsSP = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: 50,
    },
    {
      title: t("Seller Account Name"),
      dataIndex: "sellerAccountName",
      key: "sellerAccountName",
      width: 175,
    },
    {
      title: t("ARN"),
      dataIndex: "arn",
      key: "arn",
      width: 175,
    },
    {
      title: t("Region"),
      dataIndex: "region",
      key: "region",
      width: 175,
    },
    {
      title: t("Marketplace"),
      dataIndex: "marketplace",
      key: "marketplace",
      width: 175,
    },
    {
      title: t("Created At"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 175,
    },
    {
      title: t("Updated At"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 175,
    },
  ];

  const dataAds = [
    {
      key: "1",
      sellerAccountName: "Seller A",
      profileId: "PID123",
      countryCode: "US",
      currencyCode: "USD",
      timeZone: "EST",
      createdAt: "2024-10-10",
      updatedAt: "2024-10-11",
    },
    {
      key: "2",
      sellerAccountName: "Seller B",
      profileId: "PID456",
      countryCode: "UK",
      currencyCode: "GBP",
      timeZone: "GMT",
      createdAt: "2024-10-09",
      updatedAt: "2024-10-10",
    },
    // Add more rows as needed
  ];

  // Define table columns
  const columnsAds = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: 75, // Width matching min-w-75px
    },
    {
      title: t("Seller Account Name"),
      dataIndex: "sellerAccountName",
      key: "sellerAccountName",
      width: 200, // Width matching min-w-200px
    },
    {
      title: t("Profile ID"),
      dataIndex: "profileId",
      key: "profileId",
      width: 100, // Width matching min-w-100px
    },
    {
      title: t("Country Code"),
      dataIndex: "countryCode",
      key: "countryCode",
      width: 125, // Width matching min-w-125px
    },
    {
      title: t("Currency Code"),
      dataIndex: "currencyCode",
      key: "currencyCode",
      width: 125, // Width matching min-w-125px
    },
    {
      title: t("Time Zone"),
      dataIndex: "timeZone",
      key: "timeZone",
      width: 100, // Width matching min-w-100px
    },
    {
      title: t("Created At"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 175, // Width matching min-w-175px
    },
    {
      title: t("Updated At"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 175, // Width matching min-w-175px
    },
  ];

  return (
    <div id="" className="fadeInUp mt-3">
      <div id="kt_app_content_container">
        <div className="row px-8">
          <div className="col-md-12 cus-tab">
            <Tabs
              activeKey={selectedType}
              onChange={(key) => setSelectedType(key)}
            >
              <Tabs.TabPane tab={t("Amazon SP API Credentials")} key="1" />
              <Tabs.TabPane tab={t("Amazon Advertising Credentials")} key="2" />
            </Tabs>
          </div>
        </div>

        <div>
          {selectedType === "1" && (
            <Card
              bodyStyle={{
                padding: "20px",
              }}
            >
              <Flex className="mb-5" justify="space-between">
                <Typography.Title level={5}>
                  {t("Amazon SP API Credentials List")}
                </Typography.Title>
                <Button type="primary" onClick={() => setSpModal(true)}>
                  {t("Login With Amazon")}
                </Button>
              </Flex>
              <Table
                columns={columnsSP}
                loading={spLoading}
                dataSource={spList
                  ?.filter((d) => d?.credential_type === "SP-API")
                  ?.map((d, i) => {
                    const credential_details = d?.credential_details
                      ? JSON.parse(d?.credential_details)
                      : {};

                    return {
                      key: i + 1,
                      sellerAccountName: decodeURIComponent(
                        credential_details?.seller_name
                      ),
                      arn: credential_details?.role_arn || "-",
                      region: d?.region || "-",
                      marketplace: d?.marketplace || "-",
                      createdAt: dayjs(
                        new Date(parseInt(d.created_at) * 1000)
                      ).format("MM/DD/YYYY hh:mm A"),
                      updatedAt: dayjs(
                        new Date(parseInt(d.updated_at) * 1000)
                      ).format("MM/DD/YYYY hh:mm A"),
                    };
                  })}
                pagination={false}
                scroll={{ x: "max-content" }}
                className="fw-bolder text-dark bg-gray-cus"
              />
            </Card>
          )}
          {selectedType === "2" && (
            <Card
              bodyStyle={{
                padding: "20px",
              }}
            >
              <Flex className="mb-5" justify="space-between">
                <Typography.Title level={5}>
                  Amazon Advertising Credentials List
                </Typography.Title>
                <Button type="primary" onClick={() => setAdsModalOpen(true)}>
                  Login With Amazon
                </Button>
              </Flex>
              <Table
                columns={columnsAds}
                dataSource={spList
                  ?.filter((d) => d?.credential_type !== "SP-API")
                  ?.map((d, i) => {
                    const credential_details = d?.credential_details
                      ? JSON.parse(d?.credential_details)
                      : {};
                    return {
                      key: i + 1,
                      sellerAccountName: credential_details?.seller_name,
                      profileId: credential_details?.profile_id,
                      countryCode: credential_details?.country_code,
                      currencyCode: credential_details?.currency_code,
                      timeZone: credential_details?.time_zone,
                      createdAt: dayjs(
                        new Date(parseInt(d.created_at) * 1000)
                      ).format("MM/DD/YYYY hh:mm A"),
                      updatedAt: dayjs(
                        new Date(parseInt(d.updated_at) * 1000)
                      ).format("MM/DD/YYYY hh:mm A"),
                    };
                  })}
                loading={spLoading}
                pagination={false}
                scroll={{ x: "max-content" }}
                className="fw-bolder text-dark bg-gray-cus"
              />
            </Card>
          )}
        </div>
      </div>
      <div id="amazon-root" />
      {spModal && (
        <SpModal
          list={spList}
          view={spModal}
          APPID={APPID}
          onClose={() => {
            setSpModal(false);
          }}
          {...props}
        />
      )}
      {adsModalOpen && (
        <AdsAmazon
          returnURL={returnURL}
          ClientID={ClientID}
          show={adsModalOpen}
          close={() => setAdsModalOpen(false)}
          {...props}
        />
      )}
      {adListModal && (
        <AdsProfiles
          show={adListModal}
          saveProfile={saveProfile}
          data={adList}
          AdsCallBack={AdsCallBack}
          getList={() => {
            getSPList();
          }}
          onHide={() => {
            setAdListModal(false);
          }}
          {...props}
        />
      )}
    </div>
  );
};

export default MarketplaceView;
