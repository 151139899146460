import { Button } from "antd";
import styled from "styled-components";
import { primaryColor, secondaryColor, thirdColor } from "../../config";

export const Wrapper = styled(Button)`
  color: ${primaryColor} !important;
  border-color: ${primaryColor} !important;
  background-color: ${thirdColor} !important;
  &:hover {
    color: #fff !important;
    background-color: ${primaryColor} !important;
  }
`;
export const ImportWrapper = styled(Button)`
  color: ${secondaryColor} !important;
  border-color: ${primaryColor} !important;
  background-color: ${thirdColor} !important;
  &:hover {
    color: #fff !important;
    background-color: ${primaryColor} !important;
  }
`;
export const ActionBTNWrapper = styled.div`
  .Download-button {
    display: flex;
    align-items: center;
    font-family: inherit;
    font-weight: 500;
    font-size: 13px;
    padding: 11px 20px;
    color: white;
    background: rgb(116 155 253);
    border: none;
    box-shadow: 0 0.7em 1.5em -0.5em rgba(59, 48, 78, 0.527);
    letter-spacing: 0.05em;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
  }

  .Download-button svg {
    margin-right: 8px;
    width: 25px;
  }

  .Download-button:hover {
    box-shadow: 0 0.5em 1.5em -0.5em rgba(88, 71, 116, 0.627);
  }

  .Download-button:active {
    box-shadow: 0 0.3em 1em -0.5em rgba(88, 71, 116, 0.627);
  }

  .Download-button::before {
    content: "";
    width: 4px;
    height: 40%;
    background-color: white;
    position: absolute;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    left: 0;
    transition: all 0.2s;
  }

  .Download-button::after {
    content: "";
    width: 4px;
    height: 40%;
    background-color: white;
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    right: 0;
    transition: all 0.2s;
  }

  .Download-button:hover::before,
  .Download-button:hover::after {
    height: 60%;
  }
`;
