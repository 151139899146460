import { Button, Card, Col, Divider, message, Row, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocale } from "antd/es/locale";
import { MakeApiCall } from "../../../../apis";
import { Wrapper } from "../style";
import CheckoutForm from "./checkout-form";

const { Title, Text } = Typography;

const stripePromise = loadStripe("pk_test_51PySt2GHXw4iXMT7aHKKLz414zKIQjnHJHIfz59GSdpi2Swm5XZJWKEUs6bq1DMD1SJwiu9ny1rJQp977vvfZ8iX00AeVN3sy4");

const Subscription = ({ userData,setIsAlreadySubscribed ,isAlreadySubscribed}) => {
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = useState("Monthly");
    const { t } = useTranslation();
    const [subscriptionPlan, setSubscriptionPlan] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkOutForm,setCheckOutForm] = useState(false)

    // // Define Stripe checkout URLs
    // const stripeCheckoutUrls = {
    //     Monthly: "https://buy.stripe.com/test_cN24jt9od3Qhf4IeUU",
    //     Yearly: "https://buy.stripe.com/test_28o8zJ0RH72t7Cg4gh"
    // };

    // const queryString = window.location.search;
    // console.log("kefgdifbidsfb",queryString)
    

    useEffect(() => {
        GetSubscriptionPlan();
       
    }, []);


    const GetSubscriptionPlan = async () => {
        setLoading(true);
        const response = await MakeApiCall(
            `stripe/products`,
            "get",
            null,
            true,
            {},
            userData?.token
        );
        if (response?.status) {
            setLoading(false);
            setSubscriptionPlan(response?.data);
        } else {
            message.warning(response?.message);
            setLoading(false);
        }
    };

    const type = {
        "Monthly" : 1,
        "Yearly": 2
    }

    const Sub_type = type[selectedType]

    console.log("selected key",Sub_type)

    const handleSubscription = () => {
        setCheckOutForm(true)
        // window.open(stripeCheckoutUrls[selectedType], "_blank");
    };
   

    const filteredPlans = subscriptionPlan.filter(plan => plan.product_type === selectedType);

    const filterePlans = filteredPlans.map((s) => s.product_price)
   
console.log('adfasdbkl',filterePlans.toString())
    return (
        <Elements stripe={stripePromise}>
            <Wrapper>
                {
                    checkOutForm ? (
                        

                        <CheckoutForm price={filterePlans.toString()} userData={userData} Sub_type={Sub_type} setIsAlreadySubscribed={setIsAlreadySubscribed} isAlreadySubscribed={isAlreadySubscribed}/>
                    ) : (
                        <>
                        <div className="d-flex justify-content-center">
                    <Tabs
                        activeKey={selectedType}
                        onChange={(key) => setSelectedType(key)}
                    >
                        <Tabs.TabPane tab={t("Monthly")} key="Monthly" />
                        <Tabs.TabPane tab={t("Yearly")} key="Yearly" />
                    </Tabs>
                </div>
                <div className="pricing-wrapper">
                    <Row gutter={[40, 16]} justify="center" align="top">
                        {filteredPlans.map(plan => (
                            <Col xs={24} md={12} key={plan.id}>
                                <div className="card pulse">
                                    <Card bordered={false} className="pricing-card">
                                        <div>
                                            <div className="pricing-header">
                                                <CheckCircleFilled className="check-icon" />
                                                <Title level={3}>{plan.product_name}</Title>
                                            </div>
                                        </div>

                                        <div className="pricing-amount">
                                            <div className="price-month">
                                                <Text className="price">${plan.product_price}</Text>
                                                <Text className="price-per-month">per {plan.product_type.toLowerCase()}</Text>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <Button 
                                                type="primary" 
                                                style={{ color: "#fff" }} 
                                                onClick={handleSubscription}
                                            >
                                                GET STARTED
                                            </Button>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
                        </>
                    )
                    
                }
                
            </Wrapper>
        </Elements>
    );
};

export default Subscription;
