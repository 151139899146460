import React from "react";
import { Wrapper } from "./style";

export default function LogoAnimation() {
  return (
    <>
      <Wrapper className="container">
        <div className="align--center">
          <svg
            className="brand anim"
            viewBox="0 0 128 128"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g
              stroke="#FFFFFF"
              strokeLinecap="round"
              fill="none"
              fillRule="evenodd"
            >
              <circle
                className="brand__outer anim"
                strokeWidth={8}
                strokeDasharray="120,300"
                strokeDashoffset={0}
                cx={64}
                cy={64}
                r={48}
              />
              <circle
                className="brand__mid anim"
                strokeWidth={8}
                strokeDasharray="125,225"
                strokeDashoffset={0}
                cx={64}
                cy={64}
                r={36}
              />
              <circle
                className="brand__inner anim"
                strokeWidth={16}
                strokeDasharray="88,124"
                strokeDashoffset={0}
                cx={64}
                cy={64}
                r={20}
              />
            </g>
          </svg>
        </div>
      </Wrapper>
    </>
  );
}
