import { Layout, Skeleton, theme } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { TableLoading } from "../components/table-animation";
import { BorderFilter } from "../config";
import { Users } from "../routers/user";
import HeaderView from "./layout/header";
import Sidebar from "./layout/sidebar";
import { Wrapper } from "./style";
import { AdminMenus } from "./layout/sidebar/menus/admin";

const { Content } = Layout;
const Page = (props) => {
  const { user, setData } = props;
  const navigate = useNavigate();
  const [reloadPage, setReloadPage] = useState(true);
  const handleRefresh = () => {
    // return new Promise((resolve) => {
    //   console.log("Refreshing...");
    //   setReloadPage(false);
    //   setTimeout(() => {
    //     setReloadPage(true);
    //     console.log("Refresh complete");
    //     resolve();
    //   }, 2000);
    // });
  };
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleLogout = () => {
    setData({});
    navigate("/sign-in");
  };

  useEffect(() => {
    if (Object.keys(user)?.length === 0) {
      handleLogout();
    }
    setLoading(false);
    window.addEventListener("storage", (e) => {
      if (e?.storageArea?.length === 0) {
        setData({});
        return navigate("/sign-in");
      }
      if (user) {
        const menusName = (
          !Object.keys(user)?.includes("admin") ? AdminMenus : Users
        )?.map((d) => `/${d?.path}`);

        navigate(
          menusName?.includes(`${location.pathname}`)
            ? window.location.pathname
            : `${menusName?.[0]}`
        );
      }
    });
    return () => {};
  }, []);

  return (
    <Wrapper>
      <Layout className="fadeIn">
        <Sidebar
          user={user}
          setUser={setData}
          type={user?.loginType}
          collapsed={collapsed}
          location={location}
          setData={setData}
          handleLogout={handleLogout}
          setCollapsed={setCollapsed}
        />

        <Layout
          id="root-scroll"
          style={{
            overflow: "auto",
            height: "calc(100vh - 10px)",
            paddingTop: "0px",
            background: "#EEEEFF",
          }}
        >
          <HeaderView
            collapsed={collapsed}
            user={user}
            location={location}
            setData={setData}
            setCollapsed={setCollapsed}
            handleLogout={handleLogout}
            colorBgContainer={colorBgContainer}
          />
          {reloadPage ? (
            <Content
              style={{
                margin: "14px 15px 10px 15px",
                minHeight: 280,
                background: "transparent",
                borderRadius: borderRadiusLG,
              }}
            >
              <Outlet />
            </Content>
          ) : (
            <>
              <div
                className="card pulse mb-3 mt-4 px-3"
                style={{ border: BorderFilter }}
              >
                <div className="card-header d-flex justify-content-between align-items-center">
                  <div className="card-title  d-flex  align-items-center">
                    <Skeleton.Input active />
                  </div>
                  <div className="card-toolbar">
                    <Skeleton.Button active />
                  </div>
                </div>
              </div>
              <div className="card pulse ">
                <div
                  style={{
                    padding: "0px 15px",
                    marginTop: "10px",
                  }}
                >
                  <TableLoading
                    id="test-table"
                    columns={[1, 2, 3, 4, 5]}
                    checkBoxVal={true}
                    actions={[1, 2]}
                  />
                </div>
              </div>
            </>
          )}
        </Layout>
      </Layout>
    </Wrapper>
  );
};

export default Page;
