import { useTranslation } from "react-i18next";

export const ProjectName = "RIDI";
export const DefaultUserPage = `/dashboard`;
export const DefaultAdminPage = `/manage-user`;

export const ORIGIN = "https://api.amz-profit.com";

export const ENDPOINT = `${ORIGIN}/api/v1/`;
export const Types = {
  1: "admin",
  2: "user",
};
export const APPID = "";
export const ClientID = "";
export const primaryColor = "#AF69ED"; // medium
export const secondaryColor = "#4B0076"; // dark
export const thirdColor = "#311465"; // light

export const BorderFilter = `1px solid ${primaryColor}`;
export const FormatUserName = (userName) => {
  // Replace hyphens with spaces
  let formattedName = userName?.replace(/-/g, " ");

  // Capitalize the first letter of each word
  formattedName = formattedName?.replace(/\b\w/g, function (char) {
    return char?.toUpperCase();
  });

  return formattedName === "Sku" ? "SKU" : formattedName;
};
export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};
export const RenderTable = (props, row, index, d, width) =>
  typeof d?.render === "function" ? (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        {d?.render(props, row, index)}
      </div>
    </>
  ) : (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        <span>{props || "-"}</span>
      </div>
    </>
  );
export const ConvertParams = (values) => {
  return Object.entries(
    Object.entries(values)
      .filter(
        ([_, value]) =>
          value &&
          value !== "" &&
          value !== undefined &&
          value !== null &&
          value?.length !== 0
      )
      .reduce((acc, [key, value]) => {
        acc[key] = encodeURIComponent(value);

        return acc;
      }, {})
  )
    ?.map((d, i) => {
      return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
    })
    ?.join("");
};
export const AddCommas = (number) => {
  return parseFloat(number)
    .toFixed(Math.max(0, Math.min(2, (number + "").split(".")[1]?.length)))
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const PaginationConfig = ({
  pageSize,
  currentPage,
  totalPage,
  onChange = () => {},
}) => {
  const { t } = useTranslation();
  return {
    pageSize: pageSize,
    current: currentPage,
    total: totalPage,
    onChange: (e, f) => {
      onChange(e, f);
    },
    showSizeChanger: true, // To show page size options
    pageSizeOptions: [10, 20, 50, 100], // Page size options
    showTotal: (total, range) =>
      `${AddCommas(range[0] || 0)}-${AddCommas(range[1] || 0)} of ${AddCommas(
        total || 0
      )} ${t("items")}`,
  };
};

function getMonthNumber(monthAbbr) {
  const date = new Date(`${monthAbbr} 1, 2000`);
  return date.getMonth() + 1; // Adding 1 to make it 1-based (January = 1, ..., December = 12)
}

export const FormatDateRange = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const startMonth = startDate.toLocaleString("en-US", { month: "short" });
  const startDay = startDate.getDate();
  const startYear = startDate.getFullYear();

  const endMonth = endDate.toLocaleString("en-US", { month: "short" });
  const endDay = endDate.getDate();
  const endYear = endDate.getFullYear();

  let result = "";

  console.log(startDay, startMonth, startYear, "startDay");

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      if (startDay === endDay) {
        result = `${startDay}.${getMonthNumber(startMonth)}.${startYear}`;
      } else {
        result = `${startDay}.${getMonthNumber(
          startMonth
        )} - ${endDay}.${getMonthNumber(endMonth)}.${startYear}`;
      }
    } else {
      result = `${startDay}.${getMonthNumber(
        startMonth
      )} - ${endDay}.${getMonthNumber(endMonth)}.${startYear}`;
    }
  } else {
    result = `${startDay}.${getMonthNumber(
      startMonth
    )}.${startYear} -  ${endDay}.${getMonthNumber(endMonth)}.${endYear}`;
  }

  return result;
};
