import styled from "styled-components";
import { secondaryColor, thirdColor } from "../../../config";

export const Wrapper = styled.div`
  .prosess-card {
    background: #fff;
    border: 1px dashed #534cab65;
    padding: 10px;
    border-radius: 18px;
    .ant-collapse-header {
      padding: 0px 16px !important;
    }
  }

  .custom-card {
    background-color: ${secondaryColor};
    color: white;
    text-align: center;
    border-radius: 10px;
  }

  .card-value {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
  }

  .card-title {
    font-size: 16px;
    margin-top: 5px;
    color: #ffffff;
  }

  .card-percent {
    font-size: 14px;
    margin-top: 10px;
    color: #4abdf5;
    font-weight: bold;
  }

  .ant-card {
    background-color: ${secondaryColor};
    border: none;
  }

  .ant-row {
    margin-top: 20px;
  }

  .ant-col {
    padding: 0;
  }

  .custom-card {
    background-color: ${thirdColor};

    color: white;
    text-align: center;
    border-radius: 15px;
    padding: 20px 10px;
    padding-bottom: 10px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;

    .ant-card-body {
      padding: 0;
    }
  }
  .first-card {
    height: 100%;
    display: grid;
    align-items: center;
  }

  .custom-card:hover {
    transform: translateY(-5px);
  }

  .card-value {
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .card-title {
    font-size: 14px;
    color: #ffffff;
    font-weight: 400;
  }

  .card-percent {
    font-size: 14px;
    margin-top: 12px;
    color: #002b60;
    font-weight: 600;
    background: #ffffff;
    border-radius: 28px;
  }

  .ant-card {
    border: none;
  }

  .product-show-card {
    .ant-card-body {
      padding: 0 20px !important;
    }
  }
`;
