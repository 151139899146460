import { Col } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-collapse-header {
    padding: 0px 0px !important;
  }
  .ant-collapse-content-box {
    padding: 0px 0px !important;
  }
  .prosess-card {
    background: #fff;
    border: 1px dashed #534cab65;
    padding: 10px;
    border-radius: 18px;
    box-shadow: rgba(255, 255, 255, 0.9) 0px 0px 1px 1px inset,
      rgba(0, 0, 0, 0.05) 0px 20px 27px 0px;
  }

  .ant-card .ant-card-head {
    padding: 0px !important;
  }
`;
export const CardViewWrapper = styled(Col)``;
