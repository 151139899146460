import { Button, Col, Form, Input, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MakeApiCall } from "../../../apis";
import {
  DefaultUserPage,
  primaryColor,
  secondaryColor,
  Types,
} from "../../../config";
import { LoginResponse } from "../../../config/fakeData";
import { setData } from "../../../redux/actions";
import { useTranslation } from "react-i18next";

const WrapperSignIn = styled.div`
  .login-page {
    display: flex;
    height: 100%;
    background-color: #f5f7fb;
  }
  .link-login {
    color: ${secondaryColor} !important;
  }

  .login-left {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    text-align: center;
    background: #f3f5ff;
    border-radius: 15px;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }

  .login-illustration {
    width: 400px;
    margin-bottom: 20px;
  }

  .login-left h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }

  .login-left p {
    font-size: 16px;
    color: #666;
  }

  .login-right {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(#7695ff33 1px, transparent 1px),
      radial-gradient(#7695ff54 1px, transparent 1px);
    background-size: 22px 22px;
    background-position: 0 0, 11px 11px;
    background-color: #ffffff;
    padding: 40px;
  }
  .fadeInUp {
    padding: 25px;
    display: grid;
    justify-items: center;
    border-radius: 18px;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #e7e7e7;
  }

  .login-right h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 10px;
  }

  .login-right p {
    font-size: 14px;
    color: #999;
    margin-bottom: 20px;
  }

  .login-form {
    width: 400px;
  }

  .login-button {
    width: 100%;
    margin-top: 10px;
  }

  .extra-links {
    text-align: center;
    margin-top: 10px;
  }

  .extra-links a {
    color: #1890ff;
  }
`;

export default function SignUp() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleLogin = (data) => {
    const type = Types?.[data?.data?.user_data?.user_type];
    const obj = {
      user: data?.data,
      loginType: type,
      token: data?.data?.auth_token_data?.access_token,
    };
    dispatch(setData(obj));
    navigate(DefaultUserPage);
  };

  useEffect(() => {
    if (Object.keys(user)?.length !== 0) {
      setLoading(false);
      navigate(DefaultUserPage);
    }
    return () => {};
  }, []);

  const onFinish = async (values) => {
    delete values.confirm;

    setSubmitLoading(true);
    const response = await MakeApiCall("signup", "post", { ...values });

    if (response?.status === true) {
      handleLogin(response);
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
      message.destroy();
      message.error(response?.message);
    }
  };

  return (
    <WrapperSignIn>
      <div className="login-page">
        {/* Left Section */}
        <Row style={{ height: "100vh", background: `${secondaryColor}8c` }}>
          <Col xxl={12} md={12} sm={24} style={{ background: "#FFF" }}>
            <div className="login-right ">
              <div className="fadeInUp">
                <h2 className="mb-7">{t("Sign Up")}</h2>

                <Form
                  name="login"
                  onFinish={onFinish}
                  layout="vertical"
                  className="login-form"
                >
                  <Form.Item
                    name="name"
                    hasFeedback
                    rules={[
                      { required: true, message: t("Please input your name!") },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: "18px",
                        fontSize: "14px",
                        padding: "12px",
                      }}
                      size="large"
                      placeholder={t("Name")}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("Please input your email!"),
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: "18px",
                        fontSize: "14px",
                        padding: "12px",
                      }}
                      size="large"
                      placeholder={t("Email")}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("Please input your password!"),
                      },
                    ]}
                  >
                    <Input.Password
                      style={{
                        borderRadius: "18px",
                        fontSize: "14px",
                        padding: "12px",
                      }}
                      size="large"
                      placeholder={t("Password")}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("Please confirm your password!"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              t(
                                "The two passwords that you entered do not match!"
                              )
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      style={{
                        borderRadius: "18px",
                        fontSize: "14px",
                        padding: "12px",
                      }}
                      size="large"
                      placeholder={t("Confirm Password")}
                    />
                  </Form.Item>
                  <Form.Item
                    name="contact_no"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("Please input your contact number!"),
                      },
                      {
                        pattern: new RegExp(
                          /^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/
                        ),
                        message: t("Invalid contact number!"),
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: "18px",
                        fontSize: "14px",
                        padding: "12px",
                      }}
                      size="large"
                      placeholder={t("Contact No")}
                    />
                  </Form.Item>

                  <Form.Item
                    name="seller_name"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("Please input your seller name!"),
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/),
                        message: t("Invalid seller name!"),
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: "18px",
                        fontSize: "14px",
                        padding: "12px",
                      }}
                      size="large"
                      placeholder={t("Seller Name")}
                    />
                  </Form.Item>

                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={submitLoading}
                    style={{
                      borderRadius: "18px",
                      fontSize: "14px",
                      padding: "12px",
                      backgroundColor: secondaryColor,
                    }}
                    className="login-button"
                  >
                    {t("Sign Up")}
                  </Button>
                </Form>
                <div className="extra-links">
                  <p className="mt-4">
                    {t("Already have an account?")}{" "}
                    <Link
                      className="link-login"
                      style={{ color: secondaryColor }}
                      to="/sign-in"
                    >
                      {t("Sign In")}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xxl={12} md={12} sm={24} style={{ padding: "15px" }}>
            <div className="login-left">
              <div>
                <img
                  src="/assets/sign-up.svg"
                  alt="Illustration"
                  className="login-illustration fadeInDown"
                />
                <div></div>
              </div>
              <h1>{t("Fast, Efficient and Productive")}</h1>
              <p>{t("login_sub_title")}</p>
            </div>
          </Col>
        </Row>
      </div>
    </WrapperSignIn>
  );
}
