import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Flex,
  InputNumber,
  List,
  message,
  Progress,
  Row,
  Skeleton,
  Typography,
} from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DotChartOutlined } from "@ant-design/icons";
import { ArrowUpOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { AddCommas, primaryColor, secondaryColor } from "../../../config";
import { MakeApiCall } from "../../../apis";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";

const { Title, Paragraph, Text } = Typography;

const Goal = (props) => {
  const { userData } = props;
  const { t } = useTranslation();
  const [goalSummaryLoading, setGoalSummaryLoading] = useState(true);
  const [goalSummaryList, setGoalSummaryList] = useState({});
  const [forcastData, setForcastData] = useState({
    selling_price: 0,
    ppc: 0,
    cog: 0,
    storage: 0,
  });
  const [goalData, setGoalData] = useState({});
  const [goalDataLoading, setGoalDataLoading] = useState(true);
  const [graphData, setGraphData] = useState([]);
  const [graphDataLoading, setGraphDataLoading] = useState(true);

  const GoalSummaryAction = async (data) => {
    setGoalSummaryLoading(true);
    const response = await MakeApiCall(
      `goal/summary`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setGoalSummaryList(response?.data || {});
      setGoalSummaryLoading(false);
    } else {
      setGoalSummaryList([]);
      message.warning(response?.message);
      setGoalSummaryLoading(false);
    }
  };

  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#FFC300",
    "#7DFF33",
    "#FF5733",
    "#C70039",
    "#900C3F",
    "#581845",
    "#FF6F61",
    "#1F77B4",
    "#FF8C00",
    "#D62728",
    "#2CA02C",
    "#9467BD",
    "#8C564B",
    "#E377C2",
    "#7F7F7F",
    "#BCBD22",
    "#17BECF",
    "#8B4513",
    "#00CED1",
    "#32CD32",
    "#B22222",
    "#FF1493",
    "#DC143C",
    "#FF4500",
    "#2E8B57",
    "#A52A2A",
    "#4B0082",
    "#98FB98",
    "#FFFF00",
    "#FFD700",
    "#FF6347",
    "#40E0D0",
    "#ADFF2F",
    "#F0E68C",
    "#D2691E",
    "#FF00FF",
    "#8A2BE2",
    "#E6E6FA",
    "#FF7F50",
    "#8B0000",
    "#B8860B",
    "#006400",
    "#BDB76B",
    "#8FBC8F",
    "#7FFF00",
    "#D3D3D3",
  ];

  const GetGoalProductMonthlyProfit = async () => {
    setGraphDataLoading(true);
    setGraphData([]);
    const response = await MakeApiCall(
      `goal/product-monthly-profit`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setGraphData(response?.data || []);

      setGraphDataLoading(false);
    } else {
      setGraphData([]);
      message.warning(response?.message);
      setGraphDataLoading(false);
    }
  };

  const GoalMonthlyProfitAction = async () => {
    setGoalDataLoading(true);
    setGoalData({});
    const response = await MakeApiCall(
      `goal/monthly-profit-goal`,
      "get",
      null,
      true,
      {},
      userData?.token
    );
    if (response?.status) {
      setGoalDataLoading(false);
      setGoalData(response?.data?.profit_data || {});
    } else {
      setGoalDataLoading(false);
      setGoalData({});
      message.warning(response?.message);
    }
  };

  const callAPI = async () => {
    await GoalMonthlyProfitAction();
    await GoalSummaryAction();
    await GetGoalProductMonthlyProfit();
  };
  useEffect(() => {
    callAPI();
    return () => {};
  }, []);

  const options = {
    chart: {
      type: "pie",
    },
    colors: colors, // Define colors for each slice here
    labels: graphData?.map((item) => item?.sku), // Labels for pie slices
    legend: {
      show: false,
    },
    title: {
      text: "Net Profit by SKU", // Chart Title
      align: "center", // Align title to center
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#333",
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value?.toLocaleString() + "€"; // Add commas for thousands separator
        },
      },
      theme: "light", // Ensures a white background
      style: {
        fontSize: "14px",
        fontFamily: "Arial, sans-serif",
        color: "#000", // Text color
      },
      fillSeriesColor: false,
    },
  };

  const series = graphData?.map((item) => item?.monthly_net_profit) || [];

  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex align-items-center justify-content-center ">
          More <Icon icon="hugeicons:unfold-more" />
        </div>
      ),
      children: (
        <Row gutter={[16, 16]}>
          <Col style={{ display: "flex", alignItems: "center" }} flex={"none"}>
            <Flex>
              {graphDataLoading ? (
                <Skeleton
                  style={{ width: "350px" }}
                  paragraph={{
                    rows: 4,
                  }}
                />
              ) : (
                <div>
                  <List
                    size="small"
                    dataSource={[...graphData?.slice(0, 5)]}
                    renderItem={(item, i) => (
                      <List.Item>
                        <div
                          style={{
                            background: colors?.[i],
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        ></div>
                        <span className="product-name">
                          {item?.product_name || "-"}
                        </span>
                      </List.Item>
                    )}
                  />
                  <div style={{ padding: "5px 35px" }}>
                    <b>Others...</b>
                  </div>
                </div>
              )}
            </Flex>
          </Col>
          <Col flex={"auto"}>
            <Flex justify="center">
              {graphDataLoading ? (
                <div
                  style={{
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton.Node active>
                    <DotChartOutlined
                      style={{
                        fontSize: 40,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </div>
              ) : (
                <Chart
                  options={options}
                  series={series}
                  type="pie"
                  width="500px"
                />
              )}
            </Flex>
          </Col>
        </Row>
      ),
      showArrow: false,
    },
  ];

  const CardData = [
    {
      title: t("Revenue"),
      value: goalSummaryList?.summary?.sales,
      percent: null,
    },
    {
      title: t("PPC"),
      value: goalSummaryList?.summary?.ppc,
      percent: goalSummaryList?.summary?.cost_pect?.ppc_pect,
    },
    {
      title: t("Cost of Goods"),
      value: goalSummaryList?.summary?.more_data?.cost_of_goods?.total_cogs,
      percent: null,
    },
    {
      title: t("Storage"),
      value: goalSummaryList?.summary?.storage,
      percent: null,
    },
    {
      title: t("Profit"),
      value: goalSummaryList?.summary?.more_data?.net_profit,
      percent: null,
    },
  ];

  const [ppc, setPpc] = useState(10.0);

  const monthlyProfit = 2268;
  const goalProfit = 2484;

  const progressPercentage = (monthlyProfit / goalProfit) * 100;

  return (
    <Wrapper className="fadeInUp">
      <div className="mb-3 prosess-card">
        <Row className="d-flex justify-content-between" gutter={[16, 16]}>
          <Col span={24}>
            <Row className="d-flex justify-content-between" gutter={[16, 16]}>
              <Col
                className="d-flex justify-content-start align-items-center"
                flex={"none"}
              >
                <Typography>
                  <Title level={4}>
                    {t("Monthly profit goal:")}{" "}
                    <Icon
                      style={{ position: "relative", top: "3px" }}
                      icon="material-symbols:info"
                    />
                  </Title>

                  <Paragraph className="mb-0">
                    <small>*</small>
                    <span style={{ fontSize: "0.8rem" }}>
                      {t("Average Profit Over the Last 12 Month")}
                    </span>
                  </Paragraph>
                </Typography>
              </Col>
              <Col className="d-flex align-items-center " flex={"auto"}>
                <div className="d-grid px-10" style={{ width: "100%" }}>
                  <Progress
                    percent={parseInt(goalData?.profit_pect || 0)}
                    percentPosition={{
                      align: "end",
                      type: "inner",
                    }}
                    size={["100%", 20]}
                    strokeColor="#2e9520"
                  />
                  <div className="d-flex justify-content-center align-items-center mt-2">
                    <span>
                      {AddCommas(parseInt(goalData?.monthly_profit || 0))}€
                    </span>
                    &nbsp;<b>/</b>&nbsp;
                    <b>
                      {AddCommas(parseInt(goalData?.monthly_profit_goal || 0))}€
                    </b>
                  </div>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-end align-items-center"
                // span={4}
                flex={"none"}
              >
                <Button style={{ borderRadius: "7px" }} type="primary">
                  {t("Analyse")}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Divider className="mt-1 mb-0" />
          </Col>

          <Col className="pb-1" span={24}>
            <Collapse bordered={false} items={items} />
          </Col>
        </Row>
      </div>
      <Row className="mb-3" gutter={[16, 16]} justify="center" align="stretch">
        {CardData.map((item, index) => (
          <Col key={index} flex="auto">
            <Card className={`custom-card ${index === 0 ? "first-card" : ""}`}>
              <div className="card-value">
                {goalSummaryLoading ? (
                  <Skeleton.Button active />
                ) : (
                  `${AddCommas(parseInt(item.value || 0))}€`
                )}
              </div>
              <div className="card-title">{item.title}</div>
              {/* {item.percent !== null && (
                <div className="card-percent">
                  {goalSummaryLoading ? (
                    <Skeleton.Input active />
                  ) : (
                    `${AddCommas(parseInt(item.percent || 0))}%`
                  )}
                </div>
              )} */}
            </Card>
          </Col>
        ))}
      </Row>
      <Card
        title={
          <Text style={{ color: secondaryColor, fontSize: 18 }}>
            {t("Forecast")}
          </Text>
        }
        bordered={false}
        className="form-card"
        style={{
          border: `2px dashed ${secondaryColor}`,
          backgroundColor: `${secondaryColor}09`,
          borderRadius: "12px",
          padding: "0px 16px",
        }}
      >
        <Row gutter={16} align="middle">
          <Col span={12}>
            <Text strong style={{ fontSize: 16, color: secondaryColor }}>
              {t("Cost Reduce to achieve the goal:")}
            </Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Progress
              percent={progressPercentage}
              status="active"
              strokeColor={primaryColor}
              showInfo={false}
              size={{ width: "100%", height: 30 }}
              style={{ width: "100%", marginRight: "10px" }}
            />
            <Text style={{ fontSize: 16 }}>{`${Math.round(
              progressPercentage
            )}%`}</Text>
          </Col>
        </Row>

        <Divider className="my-2" />

        {/* Inputs Section */}
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={6}>
            <Text> {t("Selling price")} </Text>
            <InputNumber
              value={forcastData.selling_price}
              onChange={(e) => {
                setForcastData({
                  ...forcastData,
                  selling_price: e,
                });
              }}
              style={{
                width: "100%",
                borderRadius: "16px",
                borderColor: "#0073e6",
                backgroundColor: "#ffffff",
              }}
            />
          </Col>
          <Col span={6}>
            <Text> {t("PPC")} </Text>
            <InputNumber
              value={forcastData.ppc}
              onChange={(e) => {
                setForcastData({
                  ...forcastData,
                  ppc: e,
                });
              }}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              min={0}
              max={100}
              style={{
                width: "100%",
                borderRadius: "16px",
                borderColor: "#0073e6",
                backgroundColor: "#ffffff",
              }}
            />
          </Col>
          <Col span={6}>
            <Text> {t("COG")} </Text>
            <InputNumber
              value={forcastData.cog}
              onChange={(e) => {
                setForcastData({
                  ...forcastData,
                  cog: e,
                });
              }}
              style={{
                width: "100%",
                borderRadius: "16px",
                borderColor: "#0073e6",
                backgroundColor: "#ffffff",
              }}
            />
          </Col>
          <Col span={6}>
            <Text> {t("Storage")} </Text>
            <InputNumber
              value={forcastData.storage}
              onChange={(e) => {
                setForcastData({
                  ...forcastData,
                  storage: e,
                });
              }}
              style={{
                width: "100%",
                borderRadius: "16px",
                borderColor: "#0073e6",
                backgroundColor: "#ffffff",
              }}
            />
          </Col>
        </Row>

        <Divider className="my-2" />

        {/* Changes Section */}
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={12}>
            <Text> {t("Selling price not changed")} </Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text>{AddCommas(forcastData?.selling_price || 0)}€</Text>
          </Col>
          <Col span={12}>
            <Text>
              {t("PPC reduced to")} {ppc}%
            </Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text>
              <ArrowUpOutlined style={{ color: "green" }} />{" "}
              {AddCommas(forcastData?.ppc || 0)}€
            </Text>
          </Col>
          <Col span={12}>
            <Text>{t("COG not changed")}</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text>{AddCommas(forcastData?.cog || 0)}€</Text>
          </Col>
          <Col span={12}>
            <Text>{t("Storage not changed")}</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text>{AddCommas(forcastData?.storage || 0)}€</Text>
          </Col>
        </Row>

        <Divider className="my-2" />

        <Row className="pb-3" style={{ marginTop: 30 }}>
          <Col span={12}>
            <Text
              style={{ fontSize: 18, fontWeight: "bold", color: "#004080" }}
            >
              {t("Monthly profit right now:")} {monthlyProfit}€
            </Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text
              style={{ fontSize: 18, fontWeight: "bold", color: "#52c41a" }}
            >
              {AddCommas(
                Object.values(forcastData || {})?.reduce(
                  (total, num) => total + num,
                  0
                )
              )}
              €
            </Text>
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
};

export default Goal;
