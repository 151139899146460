import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  .align--center {
    width: 100%;
    text-align: center;
  }

  .anim {
    animation-timing-function: cubic-bezier(0.45, 0.7, 0.15, 1);
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
  }

  .brand {
    width: 128px;
    height: 128px;
    transform: rotate(-180deg);
    transform-origin: center;
    animation-name: s;
  }

  .brand .brand__outer,
  .brand .brand__mid,
  .brand .brand__inner {
    opacity: 0;
    transform: rotate(-180deg);
    transform-origin: center;
  }

  .brand .brand__outer {
    animation-delay: 360ms;
    stroke-dashoffset: 120;
    animation-name: outer-anim;
  }

  .brand .brand__mid {
    stroke-dashoffset: 125;
    animation-name: mid-anim;
  }

  .brand .brand__inner {
    animation-delay: 640ms;
    stroke-dashoffset: 88;
    animation-name: inner-anim;
  }

  @keyframes s {
    to {
      transform: rotate(52deg);
    }
  }

  @keyframes mid-anim {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
      stroke-dashoffset: 125;
    }
    70% {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: #07587f;
      transform: rotate(0);
    }
    100% {
      stroke: #07587f;
      stroke-dashoffset: 0;
      opacity: 1;
      transform: rotate(0);
    }
  }

  @keyframes outer-anim {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
      stroke-dashoffset: 120;
    }
    60% {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: #043a54;
      transform: rotate(0);
    }
    100% {
      stroke: #043a54;
      stroke-dashoffset: 0;
      opacity: 1;
      transform: rotate(0);
    }
  }

  @keyframes inner-anim {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
      stroke-dashoffset: 88;
    }
    50% {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: #ffffff6e;
      transform: rotate(0);
    }
    100% {
      stroke: #ffffff6e;
      stroke-dashoffset: 0;
      opacity: 1;
      transform: rotate(0);
    }
  }
`;
