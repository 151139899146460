import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Input } from "antd";
import React from "react";
import Table2 from "../../../components/tables/table2";

export default function Reports() {
  const data = [
    {
      key: "1",
      reportType: "Dashboard by day",
      periodicity: "Every Monday for the previous week",
      email: "email@example.com",
    },
    {
      key: "2",
      reportType: "Stock",
      periodicity: "Every Monday",
      email: "email@example.com",
    },
    {
      key: "3",
      reportType: "Sales by product/month",
      periodicity: "Every 1st day of the month for the previous month",
      email: "email@example.com",
    },
    {
      key: "4",
      reportType: "Dashboard by product",
      periodicity: "Daily for the previous 30 days",
      email: "email@example.com",
    },
  ];

  const columns = [
    {
      title: "Report type",
      dataIndex: "reportType",
      key: "reportType",
    },
    {
      title: "Periodicity",
      dataIndex: "periodicity",
      key: "periodicity",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Actions",
      key: "actions",
      render: () => <Icon icon="material-symbols:more-vert" width={20} />,
    },
  ];
  return (
    <div className="card fadeInUp">
      <div className="card-header mt-2">
        <div className="card-title d-flex align-items-center justify-content-center">
          Reports
        </div>
        <div className="card-toolbar">
          <Input
            prefix={<Icon width={20} icon={"iconamoon:search-duotone"} />}
            placeholder="Report Name"
            className="w-200px"
          />
          <Button className="ms-3" type="dashed">
            Renew Links
          </Button>
          <Button className="ms-3" type="primary">
            <Icon icon="ic:outline-plus" />
          </Button>
        </div>
      </div>
      <div className="card-body p-5">
        <Table2
          scroll={{ x: "max-content" }}
          dataSource={data}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
}
