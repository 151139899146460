import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom";
import { UserMenus } from "./user";
import { thirdColor } from "../../../../config";
import { AdminMenus } from "./admin";
import { useTranslation } from "react-i18next";

export const FindMenus = (type) => {
  const { t } = useTranslation();
  return {
    admin: AdminMenus,
    user: UserMenus,
  }?.[type]?.map((d, i) => {
    if (d?.children) {
      return {
        ...d,
        icon: (
          <div className="p-3">
            <Icon icon={d.icon} width={22} color={thirdColor} />
          </div>
        ),

        label: t(d.label),
        children: d?.children?.map((d) => {
          return {
            ...d,
            icon: <></>,
            label: <Link to={d.key}>{d.label}</Link>,
          };
        }),
      };
    }
    return {
      ...d,
      icon: (
        <div className="p-3">
          <Icon icon={d.icon} width={22} color={thirdColor} />
        </div>
      ),
      label: <Link to={d.key}>{t(d.label)}</Link>,
    };
  });
};
