export const AdminMenus = [
  {
    label: "Dashboard",
    key: "dashboard",
    icon: "ic:round-dashboard",
  },

  {
    label: "Manage User",
    key: "manage-user",
    icon: "mdi:user",
  },
  {
    label: "Admin Scheduler",
    key: "admin-scheduler",
    icon: "clarity:administrator-solid",
  },
];
