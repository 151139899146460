import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const UserModal = ({ visible, onCancel, onSubmit, initialValues }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      setLoading(false);
      form.resetFields(); // Reset form fields when the modal is opened
      form.setFieldsValue(initialValues || { user_type: 2 }); // Set initial values or default to user_type = 2
    }
  }, [visible, form, initialValues]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        onSubmit(values);
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  return (
    <Modal
      visible={visible}
      title={initialValues ? t("Edit User") : t("Create a New User")}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t("Cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          {initialValues ? t("Update") : t("Submit")}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="userForm">
        <Form.Item
          name="name"
          label={t("Name")}
          rules={[{ required: true, message: t("Please input your name!") }]}
        >
          <Input placeholder={t("Enter your name")} />
        </Form.Item>
        <Form.Item
          name="email"
          label={t("Email")}
          rules={[
            { required: true, message: t("Please input your email!") },
            { type: "email", message: t("Please enter a valid email!") },
          ]}
        >
          <Input autoComplete="off" placeholder={t("Enter your email")} />
        </Form.Item>
        <Form.Item
          name="seller_name"
          label={t("Seller Name")}
          rules={[
            { required: true, message: t("Please input your Seller Name!") },
          ]}
        >
          <Input autoComplete="off" placeholder="Enter your Seller Name" />
        </Form.Item>
        <Form.Item
          name="contact_no"
          label={t("Contact No")}
          rules={[
            {
              required: true,
              message: t("Please input your contact number!"),
            },
            {
              pattern: new RegExp(/^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/),
              message: t("Invalid contact number!"),
            },
          ]}
        >
          <Input
            autocomplete="new-password"
            autoComplete="new-password"
            placeholder={t("Contact No")}
          />
        </Form.Item>

        {!initialValues && (
          <>
            <Form.Item
              name="password"
              label={t("Password")}
              rules={[
                { required: true, message: t("Please input your password!") },
              ]}
            >
              <Input.Password
                autocomplete="new-password"
                autoComplete="new-password"
                placeholder={t("Enter your password")}
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label={t("Confirm Password")}
              dependencies={["password"]}
              rules={[
                { required: true, message: t("Please confirm your password!") },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("Passwords do not match!"))
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t("Confirm your password")} />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default UserModal;
