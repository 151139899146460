import React, { useState } from "react";
import { useStripe, useElements, CardElement,EmbeddedCheckoutProvider,
    EmbeddedCheckout } from "@stripe/react-stripe-js";
// import {
    
//   } from '@stripe/react-stripe-js';
import styled from "styled-components";
import { message } from "antd";
import { MakeApiCall } from "../../../../apis";
// import SuccessfullPaymentList from "./successfull-payment-list";
import { useNavigate } from "react-router-dom";
import SuccessfullPaymentList from "../index";

// Styled Components
const CheckoutWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.12);
  }
`;

const FormTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CardElementContainer = styled.div`
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #f8fafc;
  transition: all 0.2s ease;
  
  &:focus-within {
    border-color: #4f46e5;
    box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
  }
`;

const SubmitButton = styled.button`
  padding: 0.75rem;
  background-color: #4f46e5;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover:not(:disabled) {
    background-color: #4338ca;
  }
  
  &:disabled {
    background-color: #a5b4fc;
    cursor: not-allowed;
  }
`;

const CardDetailsDisplay = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
`;

const DetailTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.5rem;
`;

const DetailContent = styled.pre`
  font-family: monospace;
  padding: 1rem;
  background-color: #f1f5f9;
  border-radius: 6px;
  overflow-x: auto;
  font-size: 0.875rem;
`;

const PriceInfo = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: #2d3748;
`;

const CheckoutForm = (props) => {
  const { price, userData, Sub_type ,setIsAlreadySubscribed,isAlreadySubscribed} = props;
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [cardDetails, setCardDetails] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false); // New state for tracking payment success

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }
    
    setIsProcessing(true);
    
    try {
      const { token, error } = await stripe.createToken(elements.getElement(CardElement));
      
      if (error) {
        console.error("Error:", error);
        message.error(`Error: ${error.message}`);
      } else {
        const paymentData = {
          stripe_product_catalog_id: Sub_type,
          card_token: token.id,
          card_id: token.card.id,
          card_brand: token.card.brand,
          card_last_four: token.card.last4,
        };

        const response = await MakeApiCall(
          "subscription/create",
          "post",
          paymentData,
          true,
          {},
          userData?.token
        );

        if (response.status === true) {
          console.log("Payment Response:", response);
          message.success("Subscription created successfully");
          setIsAlreadySubscribed(true)
          setCardDetails(paymentData);
        } else {
          message.error(response.message || "Payment failed");
        }
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      message.error("An unexpected error occurred");
    } finally {
      setIsProcessing(false);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  // If payment was successful, show the SuccessfullPaymentList component
  // if (isAlreadySubscribed) {
  //   return <SuccessfullPaymentList userData={userData} />;
  // }

  // Otherwise, show the checkout form
  return (
    <CheckoutWrapper className="wrapper">
      <FormTitle>Secure Payment</FormTitle>
      
      <PriceInfo>
        Total: ${price}
      </PriceInfo>
      
      <Form onSubmit={handleSubmit}>
        <CardElementContainer>
          <CardElement options={cardElementOptions} />
        </CardElementContainer>
        
        <SubmitButton type="submit" disabled={!stripe || isProcessing}>
          {isProcessing ? "Processing..." : "Complete Purchase"}
        </SubmitButton>
      </Form>

      {cardDetails && (
        <CardDetailsDisplay>
          <DetailTitle>Payment Details:</DetailTitle>
          <DetailContent>{JSON.stringify(cardDetails, null, 2)}</DetailContent>
        </CardDetailsDisplay>
      )}
    </CheckoutWrapper>
  );
};

export default CheckoutForm;

//   <>
// {
//     successfullPayment ? (
//         <>
//         <SuccessfullPaymentList userData={userData}/>
//         </>
//     ) : (
//         <CheckoutWrapper className="wrapper">
//         <FormTitle>Secure Payment</FormTitle>
        
//         <PriceInfo>
//           Total: ${price}
//         </PriceInfo>
        
//         <Form onSubmit={handleSubmit}>
//           <CardElementContainer>
//             <CardElement options={cardElementOptions} />
//           </CardElementContainer>
          
//           <SubmitButton type="submit" disabled={!stripe || isProcessing}>
//             {isProcessing ? "Processing..." : "Complete Purchase"}
//           </SubmitButton>
//         </Form>
  
//         {/* {cardDetails && (
//           <CardDetailsDisplay>
//           <DetailTitle>Payment Details:</DetailTitle>
//             <DetailContent>{JSON.stringify(cardDetails, null, 2)}</DetailContent>
//           </CardDetailsDisplay>
//         )} */}
//       </CheckoutWrapper>
//     )
// }

   
//     </>