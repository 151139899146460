import styled from "styled-components";
import { secondaryColor } from "../../../config";

export const ProfileView = styled.div`
  .footer-sidebar {
    display: flex;
    align-items: center;
    height: 50px;
  }
  .NameFooter {
    font-weight: 700 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px !important;
    color: ${secondaryColor} !important;
  }
  .EmailFooter {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
  }
`;
export const BackToAdminWrapper = styled.button`
  background: transparent;
  border: none;
  .Btn-Container {
    display: flex;
    width: 170px;
    height: fit-content;
    background-color: #1d2129;
    border-radius: 40px;
    box-shadow: 0px 5px 10px #bebebe;
    justify-content: space-between;
    align-items: center;
    border: none;
    cursor: pointer;
  }
  .icon-Container {
    width: 45px;
    height: 45px;
    background-color: #ff9974;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid #1d2129;
  }
  .text {
    width: calc(170px - 45px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.1em;
    letter-spacing: 1.2px;
  }
  .icon-Container svg {
    transition-duration: 1.5s;
  }
  .Btn-Container:hover .icon-Container svg {
    transition-duration: 1.5s;
    animation: arrow 1s linear infinite;
  }
  @keyframes arrow {
    0% {
      opacity: 0;
      margin-left: 0px;
    }
    100% {
      opacity: 1;
      margin-left: 10px;
    }
  }

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // gap: 10px;
  // width: 150px;
  // height: 40px;
  // font-size: 12px;
  // font-weight: 600;
  // color: white;
  // border: none;
  // background-size: 200% 200%;
  // background-color: #383838;

  // background-position: left;
  // cursor: pointer;
  // border-radius: 18px;
  // transition: all 3s;

  // &:hover {
  //   box-shadow: 0px 0px 20px #7c7c7c;
  //   background-position: right;
  // }
`;
