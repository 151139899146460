import styled from "styled-components";
import { primaryColor, thirdColor } from "../../../config";

export const Wrapper = styled.div`
/* PricingComparison.css */
.pricing-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.pricing-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  box-shadow: none;
}

.pricing-header {
  display: flex;
  align-items: center;
  gap: 10px;
  
  justify-content: center;
}

.check-icon {
  color: ${primaryColor};
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.pricing-header h3 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.subtitle {
  color: #666;
  font-size: 16px;
  display: block;
  text-align: center;
}

.pricing-amount {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin: 30px 0 20px 0;
  justify-content: center;
}
  .price-month{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }

.price {
  font-size: 30px;
  font-weight: 600;
  color: #333;
}

.price-per-month {
  color: #666;
  font-size: 16px;
  margin-bottom: 15px;
}

.conjunction {
  font-size: 24px;
  color: ${primaryColor};
  margin: 0 10px;
  font-weight: 500;
}

.transaction-fee {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
  .transaction-fees {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.fee {
  font-size: 30px;
  font-weight: 600;
  color: #333;
}

.plus {
  font-size: 18px;
  margin: 0 5px;
  color: #666;
}

.per-transaction {
  font-size: 16px;
  color: #666;
  margin-left: 5px;
}


  .ant-divider-horizontal{
  width:100px;
   background-color: ${primaryColor};
  height: 2px;
  }

.feature-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px 0;
  flex-grow: 1;
  text-align: center;
  margin:30px 0;
}

.feature-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  color: #555;
  font-size: 16px;
}

.feature-list li:before {
  content: "•";
  color: ${primaryColor};
  font-size: 20px;
  position: absolute;
  left: 0;
  top: -2px;
}



@media (max-width: 767px) {
  .pricing-card {
    margin-bottom: 20px;
  }
}
`;