import ForgotPassword from "../modules/auth/forgot-password";
import SignIn from "../modules/auth/sign-in";
import SignUp from "../modules/auth/sign-up";

export const Auth = [
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
];
