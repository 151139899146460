import { message, Popover, Tag } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";
import Table2 from "../../../components/tables/table2";
import { CFTooltip } from "../../../components/tooltip/tooltip";
import {
  AddCommas,
  ConvertParams,
  PaginationConfig,
  primaryColor,
} from "../../../config";
import { useTranslation } from "react-i18next";

// Define the columns

export default function SystemLogs({ userData }) {
  const { t } = useTranslation();
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const getTableData = async (data, type) => {
    try {
      setTableData([]);
      setTableLoading(true);
      const response = await MakeApiCall(
        `log/system-error-logs${ConvertParams(data)}`,
        "get",
        null,
        true,
        {},
        userData?.token
      );
      if (response?.status) {
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setCurrentPage(response?.data?.pagination?.page || 1);
        setPageSize(response?.data?.pagination?.page_size || 1);
        setTableData(response?.data?.records || []);

        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setTableData([]);
      setTableLoading(false);
    }
  };

  const columns = [
    {
      title: t("Sr. No."),
      width: 100,
      align: "left",
      ellipsis: true,
      render: (_, __, i) => {
        return <span>{(currentPage - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: t("Date & Time Log created"),
      width: 250,
      align: "left",
      render: (d) => {
        console.log(d, "d");

        return (
          <Tag color={primaryColor} bordered={false}>
            {dayjs(new Date(d.created_at * 1000)).format(
              "MMM DD, YYYY hh:mm A"
            )}
          </Tag>
        );
      },
    },
    {
      title: t("Event Name"),
      width: 220,
      align: "left",
      render: (d) => {
        return <b>{d.event_name || "-"}</b>;
      },
    },
    {
      title: t("Event Type"),
      width: 175,
      align: "left",
      render: (d) => {
        return <Tag color="#C8A2C9">{d.event_type || "-"}</Tag>;
      },
    },
    {
      title: t("Event Message"),
      width: 180,
      align: "left",
      ellipsis: false,
      render: (d) => {
        return (
          <CFTooltip row={3} rule title={d.error_message}>
            <div className="actionIcon">{d.error_message}</div>
          </CFTooltip>
        );
      },
    },
    {
      title: t("Event Data"),
      width: 150,
      align: "left",
      ellipsis: false,
      render: (d) => {
        if (!d?.error_data) {
          return "-";
        }

        return (
          <CFTooltip row={3} rule={false}>
            <Popover
              placement="bottom"
              title=""
              content={
                <div
                  style={{
                    width: "400px",
                    wordBreak: "break-word",
                    minHeight: "20px",
                    maxHeight: "100px",
                    overflow: "auto",
                  }}
                >
                  {d?.error_data}
                </div>
              }
              trigger="hover"
            >
              <div className="actionIcon" style={{ maxWidth: "300px" }}>
                {d.error_data}
              </div>
            </Popover>
          </CFTooltip>
        );
      },
    },
  ];

  useEffect(() => {
    setTableLoading(true);
    setTableData([]);
    getTableData({
      page: currentPage,
      page_size: pageSize,
    });
    return () => {};
  }, []);

  return (
    <>
      <div className="card pulse">
        <div style={{ padding: "0px 15px", marginTop: "10px" }}>
          <Table2
            columns={columns}
            dataSource={tableData}
            scroll={{ x: "max-content" }}
            loading={tableLoading}
            pagination={PaginationConfig({
              pageSize,
              currentPage,
              totalPage,
              onChange: (e, f) => {
                setPageSize(f);
                setCurrentPage(e);
                getTableData({
                  page: e,
                  page_size: f,
                });
              },
            })}
          />
        </div>
      </div>
    </>
  );
}
